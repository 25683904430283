<script>
import {computed, onBeforeMount, ref} from "@vue/composition-api";
//import NumberLimitation from "@/views/pages/limitation/totalCoinLimitation/numberLimitation.vue";
import TotalCoinMoneyLimitation from "@/views/pages/limitation/totalCoinLimitation/TotalCoinMoneyLimitation.vue";
import store from "@/store";

export default {
  name: "TotalCoinLimitationModal",
  components: { TotalCoinMoneyLimitation},
  setup() {
    const modalShow = ref(false);
    const method = ref('totalcoin');
    const totalCoinData = ref({});
    const thereAreLimits = ref([]);
    const whiteLabel = ref('');
   
    const computedLimit = (type) => {
      if(thereAreLimits.value.length === 0) return {};
      return thereAreLimits.value.find(v=>v.type === type);
    }
    const limitMinDep = computed(()=> computedLimit('min_dep'))
    const limitMinRet = computed(()=> computedLimit('min_ret'))
    const limitMaxRet = computed(()=> computedLimit('max_ret'))
    const limitMaxRet24H = computed(()=> computedLimit('max_ret_24h'))
    const limitTopRet= computed(()=> computedLimit('top_ret'))

    const resetData = () => {
      totalCoinData.value = {};
    }

    const searchTotalCoinLimitation = async () => {

      const result = await store.dispatch(
            "payment/getPaymentLimitation",
            {
              id: whiteLabel.value,
            }
        );

        thereAreLimits.value = result.filter(v=>v.method === method.value);
    }

    const setModal = async (flag, data,params_whitelabel,param_method='totalcoin') => {
      if(params_whitelabel)whiteLabel.value = params_whitelabel;
      if(param_method)method.value = param_method;
      await searchTotalCoinLimitation();
      if (flag) {
        totalCoinData.value = Object.assign({}, data);
        modalShow.value = true;
      } else {
        modalShow.value = false;
      }
     
     //console.log('Datos1', method.value);
     
    };

    //onBeforeMount (async ()=>await searchTotalCoinLimitation());



    return {
      modalShow,
      method,
      resetData,
      setModal,
      totalCoinData,
      thereAreLimits,
      searchTotalCoinLimitation,
      limitMinDep,
      limitMinRet,
      limitMaxRet,
      limitMaxRet24H,
      limitTopRet,
      whiteLabel
    }
  }
}
</script>

<template>
  <div>
    <b-modal
        v-model="modalShow"
        :title="$t('total_coin_limits')"
        no-close-on-backdrop
        hide-footer
        bg-variant="white"
        shadow
        backdrop
        no-close-on-esc
        centered
        size="md"
        @hidden="resetData"
    >
      <div>
        <b-tabs content-class="mt-1" v-if="totalCoinData.currencies">
          <b-tab :title="$t('min_deposit')" active>
            <total-coin-money-limitation
                type="min_dep"
                :subtitle="$t('control_min_deposit')"
                :currencies="totalCoinData.currencies"
                :total-coin-data="totalCoinData"
                :limit="limitMinDep"
                :method="method"
                @close-main-modal="setModal(false,{})"
                @reload-limits="searchTotalCoinLimitation()"
            />
          </b-tab>
          <b-tab :title="$t('min_withdraw')">
            <total-coin-money-limitation
                type="min_ret"
                :subtitle="$t('control_your_min_withdraw')"
                :currencies="totalCoinData.currencies"
                :total-coin-data="totalCoinData"
                :limit="limitMinRet"
                :method="method"
                @close-main-modal="setModal(false,{})"
                @reload-limits="searchTotalCoinLimitation()"
            />
          </b-tab>

          <b-tab :title="$t('max_withdraw')">
            <total-coin-money-limitation
                type="max_ret"
                :subtitle="$t('control_your_max_withdraw')"
                :currencies="totalCoinData.currencies"
                :total-coin-data="totalCoinData"
                :limit="limitMaxRet"
                :method="method"
                @close-main-modal="setModal(false,{})"
                @reload-limits="searchTotalCoinLimitation()"
            />
          </b-tab>

          <b-tab :title="$t('withdraw_max_number')">
            <total-coin-money-limitation
                type="max_ret_24h"
                :subtitle="$t('control_withdraw_max_number')"
                :currencies="totalCoinData.currencies"
                :total-coin-data="totalCoinData"
                :limit="limitMaxRet24H"
                :method="method"
                @close-main-modal="setModal(false,{})"
                @reload-limits="searchTotalCoinLimitation()"
            />

          </b-tab>

          <b-tab :title="$t('withdraw_top')">
            <total-coin-money-limitation
                type="top_ret"
                :subtitle="$t('control_withdraw_top')"
                :currencies="totalCoinData.currencies"
                :total-coin-data="totalCoinData"
                :limit="limitTopRet"
                :method="method"
                @close-main-modal="setModal(false,{})"
                @reload-limits="searchTotalCoinLimitation()"
            />

          </b-tab>

        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">

</style>
