<template>
  <b-container v-if="getLimitsError && !loadings.getLimitLoading">
    <b-card>
      <b-row>
        <b-col> No se pudieron cargar tus límites, inténtalo de nuevo </b-col>
        <b-col>
          <b-button
            variant="primary"
            :disabled="loadings.setLimitLoading"
            class="full-width"
            @click="initComponent()"
          >
            <feather-icon icon="RotateCcwIcon" class="mr-1" />
            Reintentar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-container>

  <b-container v-else-if="loadings.getLimitLoading">
    <b-row>
      <b-col
        md="6"
        v-for="(item, index) in whitelabelData.currencies"
        :key="index"
      >
        <b-skeleton animation="fade" class="mt-1" height="35px"></b-skeleton>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col class="d-flex justify-content-center">
        <b-skeleton
          v-for="(item, index) in 4"
          :key="index"
          animation="fade"
          width="80px"
          style="margin: 4px"
          height="30px"
        ></b-skeleton>
      </b-col>

      <b-col md="12">
        <b-skeleton animation="fade" class="mt-1" height="30px"></b-skeleton>
      </b-col>
    </b-row>
  </b-container>

  <b-container v-else>
    <h4 class="mb-1 d-flex justify-content-center">{{ subtitle }}</h4>
    <b-row v-if="Array.isArray(whitelabelData.currencies)">
      <b-col
        md="6"
        v-for="(currencyObject, index) of whitelabelData.currencies"
        :key="index"
      >
        <input
          :key="key"
          spellcheck="false"
          :value="amounts[index]"
          :disabled="loadings.setLimitLoading"
          class="mb-1 form-control"
          @change="setDataFromComponent(index, $event.target.value)"
          v-money="{
            prefix: '' + currencyObject + ' ',
            masked: false,
            precision: 2,
          }"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button
          @click="handleSetLimitDuration(item)"
          size="sm"
          :disabled="loadings.setLimitLoading"
          style="margin: 5px"
          v-for="(item, index) in limitSession"
          :key="index"
          :variant="
            currencyLimitation.durationType === item.valueType &&
            currencyLimitation.duration === item.value
              ? 'primary'
              : 'secondary'
          "
        >
          {{ item.label }}
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <b-button
          variant="primary"
          :disabled="
            (currencyLimitation.duration === 0 &&
              currencyLimitation.durationType === null) ||
            loadings.setLimitLoading
          "
          class="full-width"
          @click="handleChangeLimits()"
        >
          <b-spinner
            v-if="loadings.setLimitLoading"
            type="grow"
            label="Spinning"
            class="mr-1"
            small
          ></b-spinner>
          <feather-icon v-else icon="DollarSignIcon" class="mr-1" />
          {{ $t('buttons.save') }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { onMounted, reactive, ref } from "@vue/composition-api";
import { VMoney } from "v-money";
import { BAlert, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import BreadcrumbSeparator from "@/views/components/breadcrumb/BreadcrumbSeparator.vue";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import i18n from "@/libs/i18n";
import {translatableText} from "@core/utils/utils";

export default {
  name: "WhitelabelMoneyLimitation",
  components: { BAlert, BreadcrumbSeparator, vSelect, BFormGroup },
  directives: { VMoney },
  props: ["currencies", "subtitle", "type", "whitelabelData"],
  methods: {},
  setup(props, { emit }) {
    const toast = useToast(ToastificationContent);
    const amounts = ref([]);
    const key = ref(0);
    const getLimitsError = ref(false);
    const thereAreLimit = ref(false);
    const limitId = ref("");

    const loadings = reactive({
      setLimitLoading: false,
      getLimitLoading: false,
    });

    const currencyLimitation = reactive({
      status: "",
      duration: 0,
      durationType: null,
    });

    const limitSession = [
      { label: "1 " + i18n.t('day'), value: 1, valueType: "daily" },
      { label: "1 " + i18n.t('week'), value: 7, valueType: "daily" },
      { label: "30 " + i18n.t('days'), value: 30, valueType: "daily" },
      { label: "90 " + i18n.t('days'), value: 90, valueType: "daily" },
      { label: "180 " + i18n.t('days'), value: 180, valueType: "daily" },
      // { label: "indeterminate", value: -1 },
    ];

    const initComponent = async () => {
      try {
        //reset "currencies" array
        loadings.getLimitLoading = true;
        //whe check if the user have limit
        const thereAreLimits = await store.dispatch(
          "currencyLimitation/getLimit",
          {
            url: getRequestUrlByType("GET"),
            id: props.whitelabelData._id,
          }
        );
        if (
          Array.isArray(thereAreLimits.data) &&
          thereAreLimits.data.length > 0
        ) {
          //if the user has limits we put a control variable to indicate that we are going to update it limit
          //and, we indicate the id of the limit
          thereAreLimit.value = true;
          limitId.value = thereAreLimits.data[0]._id;
          //we set the component state
          currencyLimitation.status = thereAreLimits.data[0].status;
          currencyLimitation.duration = thereAreLimits.data[0].numberOfRange;
          currencyLimitation.durationType = thereAreLimits.data[0].range_type;
          buildLimit(thereAreLimits.data[0].limit);
        } else {
          //if there are no limits
          buildLimit(
            props.whitelabelData.currencies.map((currencyElement) => ({
              currency: currencyElement,
            }))
          );
        }
        getLimitsError.value = false;
      } catch (e) {
        notify(await translatableText({text: axiosErrorHandle(e).toString()}), "danger");
        getLimitsError.value = true;
      } finally {
        loadings.getLimitLoading = false;
      }
    };

    const setDataFromComponent = (index, event) => {
      amounts.value[index] =
        event.split(" ")[0] +
        " " +
        parseFloat(event.split(" ")[1].replace(/,/g, ""));
      // checkThereAreActivesLimits();
    };

    const getAmounts = () => {
      return amounts.value.map((e) => {
        const split = e.split(" ");
        return {
          currency: split[0],
          max: parseFloat(split[1].replace(/,/g, "")),
        };
      });
    };

    const getRequestUrlByType = (requestType) => {
      if (props.type === "deposit") {
        if (requestType === "GET") return "limit-client-deposits";
        else return "limit-client-deposit";
      }
      if (props.type === "loss") {
        if (requestType === "GET") return "limit-client-losses";
        else return "limit-client-loss";
      }
      if (props.type === "bet") {
        if (requestType === "GET") return "limit-client-bets";
        else return "limit-client-bet";
      }
    };

    const handleSetLimitDuration = (limitDurationObject) => {
      currencyLimitation.durationType = limitDurationObject.valueType;
      currencyLimitation.duration = limitDurationObject.value;
    };

    const buildLimit = (limit) => {
      limit.forEach((element) => {
        const whitelabelCurrencies = props.whitelabelData.currencies;
        const foundCurrency = whitelabelCurrencies.find(
          (currencyObject) => currencyObject === element.currency
        );
        if (foundCurrency) {
          const number = parseFloat(element.max).toFixed(2);
          amounts.value[whitelabelCurrencies.indexOf(foundCurrency)] =
            number.toString();
        }
      });
    };

    const handleChangeLimits = () => {
      if (thereAreLimit.value) handleUpdateLimit();
      else handleSetLimit();
    };

    const handleSetLimit = async () => {
      try {
        loadings.getLimitLoading = true;
        await store.dispatch("currencyLimitation/setLimit", {
          url: getRequestUrlByType("POST"),
          payload: buildPayload(),
        });
        notify(i18n.t('limits_created_correctly'), "success");
        // await initComponent();
      } catch (error) {
        notify(await translatableText({text: axiosErrorHandle(error).toString()}), "danger");
      } finally {
        emit("close-main-modal");
        setTimeout(() => {
          loadings.getLimitLoading = false;
        }, 300);
      }
    };

    const handleUpdateLimit = async () => {
      try {
        loadings.getLimitLoading = true;
        console.log(buildPayload());
        await store.dispatch("currencyLimitation/updateLimit", {
          url: getRequestUrlByType("PUT"),
          payload: buildPayload(),
          limitId: limitId.value,
        });
        notify(i18n.t('limits_changed_correctly'), "success");
        // await initComponent();
      } catch (error) {
        notify(await translatableText({text: axiosErrorHandle(error).toString()}), "danger");
      } finally {
        emit("close-main-modal");
        setTimeout(() => {
          loadings.getLimitLoading = false;
        }, 300);
      }
    };

    const buildPayload = () => {
      let allCurrenciesUnlimited = true;
      console.log(allCurrenciesUnlimited);
      const payload = {
        limit: getAmounts(),
      };
      payload.clientId = props.whitelabelData._id;
      payload.userAddId = store.state.user.user._id;
      payload.range_type = currencyLimitation.durationType;
      payload.numberOfRange = currencyLimitation.duration;
      payload.type = "USER";
      payload.status = !(
        currencyLimitation.duration === 0 &&
        currencyLimitation.durationType === null
      );
      return payload;
    };

    const checkThereAreActivesLimits = () => {
      const filterActivesLimits = getAmounts().filter(
        (element) => element.max > 0
      );
      console.log("hay limites activos?: ", filterActivesLimits.length > 0);
    };

    const notify = (message, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "DollarSignIcon",
          variant: variant,
        },
      });
    };

    onMounted(async () => {
      await initComponent();
      // checkThereAreActivesLimits();
    });

    return {
      amounts,
      getAmounts,
      limitSession,
      handleChangeLimits,
      handleSetLimitDuration,
      loadings,
      currencyLimitation,
      key,
      setDataFromComponent,
      getLimitsError,
      initComponent,
    };
  },
};
</script>

<style scoped></style>
