import { render, staticRenderFns } from "./TotalCoinMoneyLimitation.vue?vue&type=template&id=8480b99c&scoped=true"
import script from "./TotalCoinMoneyLimitation.vue?vue&type=script&lang=js"
export * from "./TotalCoinMoneyLimitation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8480b99c",
  null
  
)

export default component.exports